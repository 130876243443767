<template>
  <div>
    <aside class="left-menu-container"
      :class="flagShow ? 'slide-fade-to-left-enter-active slide-fade-to-left-enter' : 'slide-fade-to-left-leave-active slide-fade-to-left-leave-to'"
      :style="{ paddingTop: $store.state.topDownLoadIsShow ? '120px' : '64px' }">
      <div class="left-menu-container__inner">


        <nav class="primary-menu">
          <div v-for="(item, index) in Menu" :key="index" :index="item.url" @click="toPage(item.url, item.link_type)"
            class="primary-menu__item-link" :class="{ 'primary-menu__item-link-active': item.url === $route.path }">
            <el-image :src="item.navpic" v-if="item.navpic" fit="contain"
              :style="`transform: scale(${item.scale})`"></el-image>
            <span>
              {{ $t(item.title) }}
            </span>
            <i class="new" v-if="item.isNew && $store.state.isLoginStatu"></i>
          </div>
        </nav>
        <nav class="secondary-menu" v-if="$store.state.env != 'app' && $t('download_app') != 'hide'">
          <img src="@/assets/images/aside-download-icon.png" class="aside-download-icon">
          <span>{{ $t("APP Download") }}</span>
        </nav>
        <div class="left-menu-container__bonuses">
          <button class="bonus" @click="goUrl('/layout/edemption-code')"
            v-if="this.$t('menu_code_bouns') != 'menu_code_bouns'">
            <cyb-img class="bonus-img" :src="$t('menu_code_bouns')"></cyb-img>
            <div class="new" v-if="$store.getters['msg/allNoMsg']"></div>
          </button>
          <button class="bonus" @click="goUrl('/?pdd=show')" v-if="this.$t('menu_pdd') != 'menu_pdd' && isShowPdd">
            <cyb-img class="bonus-img" :src="$t('menu_pdd')"></cyb-img>
            <div class="new" v-if="$store.state.msg.noMsg.pdd"></div>
          </button>
          <button class="bonus" @click="wheelClick" v-if="$t('menu_luckywheel_is_hidden') != 'hide'">
            <cyb-img class="bonus-img" :src="$t('menu_luckywheel')"></cyb-img>
          </button>
          <button class="bonus" @click="goUrl('/sport-betting')"
            v-if="$t('menu_sport') != 'menu_sport' && $t('sport_is_hidden') != 'hide'">
            <cyb-img class="bonus-img" :src="$t('menu_sport')"></cyb-img>
          </button>
          <button class="bonus" @click="$router.push('/five-bessings')"
            v-if="$t('menu_five_bessings') != 'menu_five_bessings' && $store.state.fiveBessings.fiveBessData.id">
            <cyb-img class="bonus-img" :src="$t('menu_five_bessings')"></cyb-img>
            <div class="new" v-if="$store.state.fiveBessings.fiveBessData.num"></div>
          </button>
          <button class="bonus" @click="goUrl('/lotter')" v-if="$t('is_show_lottery_countdown') == 1">
            <cyb-img class="bonus-img" :src="$t('menu_lottery_countdown')"></cyb-img>
            <span class="countdown-txt">{{ countdown }}</span>
          </button>
          <button class="bonus" @click="goUrl('/luckySport')" v-if="this.$t('lucky_sports_is_hide') != 'hide'">
            <cyb-img class="bonus-img" :src="$t('menu_lucky_sports')"></cyb-img>
          </button>
          <button class="bonus" v-if="$t('cooperation_link') && $t('cooperation_link') != 'cooperation_link'">
            <a :href="$t('cooperation_link')" target="_blank">
              <cyb-img class="bonus-img" :src="$t('menu_cooperation')"></cyb-img>
            </a>
          </button>
        </div>

        <div class="left-menu-container__footer">
          <el-button @click="toLiveSuppert" class="button button_grey1 button_left button_fullwidth" size="mini">
            <img src="@/assets/images/livechat.png" alt="" class="icon-reddit">
            <span>{{ $t('Live Support') }}</span>
          </el-button>
          <div class="dropdown_md">
            <el-dropdown @command="handleCommand" placement="top" :hide-on-click="true" trigger="click"
              @visible-change="visibleChange">
              <div class="dropdown__trigger">
                <div class="icon">
                  <img focusable="false" aria-hidden="true" :src="langIcon" class />
                </div>
                <span class="text">{{ langType }}</span>
                <i :class="['el-icon-arrow-down el-icon--right', langVisible ? 'el-icon-arrow-down-active' : '']"></i>
              </div>
              <el-dropdown-menu slot="dropdown" class="dropdown-menu_md lan-dropdown">
                <el-dropdown-item :command="item" v-for="(item, index) in langArr" :key="`langArr${index}`">
                  <button class="dropdown-menu__link">
                    <div class="icon">
                      <img :src="item.icon" />
                    </div>
                    <span class="text">{{ item.label }}</span>
                  </button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </aside>
    <furyWheel v-if="$store.state.isLoginStatu" ref="fury" :wheelShow="wheelShow" @wheelClose="wheelClose"></furyWheel>
    <div style="height: 0; overflow: hidden;">
      <audio :src="enterMus" class="media-audio" ref="MusicEnter"></audio>
    </div>
  </div>
</template>

<script>
import svgIcon from "@/components/svg.vue";
import furyWheel from "@/components/furyWheel.vue";
import { lottery_config_api } from "@/api/lottery";
import { pdd_getData_api } from '@/api/pdd.js'
export default {
  components: {
    svgIcon,
    furyWheel,
  },
  props: {
    flagShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      timeFlag: null,
      countdown: "00:59",
      langIcon: "",
      langType: "",
      activeIndex: this.$route.path,
      isShow: true,
      isShowPdd: false,
      secondaryMenu: [
        // {
        //   title: "Cyber Raccoon Team",
        //   url: "/about-team"
        // },
        // {
        //   title: "Fairness",
        //   url: "/fairness"
        // }
      ],
      wheelShow: false,
      enterMus: require("../assets/mp3/enter.mp3"),
      cashbackPic: require("../assets/images/cashback-bg.png"),
      wheelPic: require("../assets/images/wheel.png"),
      coin1: require("../assets/images/bi.png"),
      coin2: require("../assets/images/cion.png"),
      iosBut: 0,
      langVisible: false
    };
  },
  methods: {
    visibleChange(v) {
      this.langVisible = v
    },
    // 获取pdd数据，判断pdd图片是否显示
    async getPddData() {
      const { data } = await pdd_getData_api()
      if (data.code == 1) {
        this.isShowPdd = !(data.data.length === 0)
      }
    },
    initCountdown() {
      this.timeFlag = setInterval(() => {
        const countdown = (60 - new Date().getSeconds()).toString()
        this.countdown = `00:${countdown.length == 1 ? '0' + countdown : countdown}`
      }, 1000)
    },
    // 页面跳转
    toPage(url, type) {
      if (type) {
        this.platUrl(url)
      } else {
        this.$router.push({ path: url })
      }
    },
    menuToggle(index) {
      var subshow = this.Menu[index].subshow;
      this.Menu[index].subshow = subshow === true ? false : true;
    },
    handleCommand(command) {
      this.langIcon = command.icon;
      this.langType = command.label;
      this.$helper.set('i18n_icon', this.langIcon);
      this.$helper.setLang(command.label);
      this.$i18n.locale = command.label;
      location.reload();
    },
    langChange() {
      // let rule = this.$helper.get("rule");
      this.langIcon = this.$helper.get("i18n_icon");
      this.langType = this.$helper.get("i18n_lang");
    },
    wheelClick() {
      if (!this.$store.state.isLoginStatu) {
        this.showLoginReg(0);
        return;
      }
      this.getLotteryConfig()
      this.wheelShow = true
      this.$refs.fury.bgMusShow && this.$refs.MusicEnter.play()
    },
    async getLotteryConfig() {
      await lottery_config_api().then(response => {
        let res = response.data
        if (res && res.code == 0) {
          this.errorTips(res.msg);
          return;
        }
      });
    },
    wheelClose() {
      this.wheelShow = false
    },
    toDownload() {
      let rule = this.$helper.get('rule');
      let url = this.getMobileOperatingSystem() === 'iOS' ? rule.ios_download_url : rule.android_download_url;
      if (url) {
        this.platUrl(url);
      }
    },
    toLiveSuppert() {
      const ua = navigator.userAgent
      let service_url = this.$t('service_url');
      if (/android/i.test(ua) || /iphone|ipad|ipod/i.test(ua)) {
        service_url = this.$t('mobile_service_url');
        if (/android/i.test(ua)) {
          window.open(service_url, '_blank');
        } else {
          window.location.href = service_url;
        }
      } else {
        window.open(service_url, '_blank');
      }
    },
    handleSelect() {
      this.$store.commit('changeKeepPages', 'empty')
    }
  },
  beforeDestroy() {
    clearInterval(this.timeFlag)
  },
  computed: {
    langArr() {
      let _this = this;
      let options = [];
      let selecValue = this.$helper.getLang();
      let all_lang = this.$helper.get("alllang");
      if (all_lang != null) {
        for (let i = 0; i < all_lang.length; i++) {
          options.push(all_lang[i]);
          if (selecValue == all_lang[i].value) {
            _this.currentSelect = all_lang[i];
          }
        }
      }
      return options;
    },
    Menu() {
      let arr1 = [
        {
          title: this.$t("所有游戏"),
          navpic: require("../assets/images/nav1.png"),
          url: "/all-game"
        },
        {
          title: this.$t("每日签到"),
          navpic: require("@/assets/images/singin-icon.png"),
          url: "/singin",
          isNew: this.$store.state.msg.noMsg.sign
        },
        {
          title: this.$t("VIP等级系统"),
          navpic: require("../assets/images/nav2.png"),
          url: "/rank-system",
          isNew: this.$store.state.msg.noMsg.vip_week || this.$store.state.msg.noMsg.vip_month
        },
        {
          title: this.$t("活动厅"),
          navpic: require("../assets/images/nav3.png"),
          url: "/activity-hall",
          isNew: this.$store.state.msg.noMsg.action
        }
      ];
      let arr2 = [
        {
          title: this.$t("收益池"),
          navpic: require("../assets/images/nav4.png"),
          url: "/fund"
        }
      ];
      let arr3 = [
        {
          title: this.$t("任务中心"),
          navpic: require("../assets/images/task-icon.png"),
          url: "/layout/task-center",
          show: this.$t('task_center_hide') == 'hide' ? "hidden" : '',
          isNew: this.$store.state.msg.noMsg.task
        },
        {
          title: this.$t("团队中心"),
          navpic: require("../assets/images/share-4.png"),
          url: "/team-center",
          isNew: this.$store.state.msg.noMsg.invite
        },
        {
          title: this.$t("Telegram"),
          navpic: require("../assets/images/telegram.png"),
          url: this.isMobileFlag ? this.$helper.get('rule').mobile_service_url : this.$helper.get('rule').pc_service_url,
          link_type: 2,
          show: this.$t('telegram_is_hidden') == 1 ? "hidden" : ''
        },
        {
          title: this.$t("Facebook"),
          navpic: require("../assets/images/facebook.png"),
          url: this.$t('facebook_public_url'),
          link_type: 2,
          show: this.$t('facebook_public_url') == 'facebook_public_url' ? "hidden" : ''
        },
        {
          title: this.$t("Instagram"),
          navpic: require("@/assets/images/instagram.png"),
          url: this.$t('instagram_public_url'),
          scale: .8,
          link_type: 2,
          show: this.$t('instagram_public_url') == 'instagram_public_url' ? "hidden" : ''
        },
        {
          title: this.$t("Whatsapp"),
          navpic: require("../assets/images/whatsapp2.png"),
          url: this.$helper.get('rule').whatsapp_public_url,
          link_type: 2,
          show: this.$helper.get('rule').whatsapp_public_url ? "" : 'hidden'
        },
        // {
        //   title: this.$t("消息"),
        //   navpic: require("../assets/images/nav7.png"),
        //   url: "/news",
        //   show:this.$store.state.mediaShow
        // },
        // {
        //   title: this.$t("关于运动"),
        //   navpic: require("../assets/images/nav8.png"),
        //   url: "/about-sport"
        // },
      ];
      if (this.$helper.get("rule").fundStatus == 1) {
        return [...arr1, ...arr2, ...arr3].filter(item => item.show !== 'hidden');
      } else {
        return [...arr1, ...arr3].filter(item => item.show !== 'hidden');
      }
    }
  },
  async mounted() {
    this.initCountdown()
    this.getPddData()
    await this.langChange();
  },
  created() {
    this.langChange();
  },
  watch: {
    "$store.state.isLoginStatu": function (v) {
      if (v) {
        this.getPddData()
      }
    },
    $route(to) {
      if (to.query.dialog === 'wheel') {
        this.wheelClick()
      }
    }
  }
};
</script>
<style scoped>
/* aside */
.left-menu-container {
  padding-top: 64px;
  position: fixed;
  z-index: 1203;
  left: 0;
  top: 0;
  bottom: 0;
  width: 232px;
  background-color: #111923;
}

.left-menu-container .social-icons {
  margin-top: 20px;
}

.left-menu-container__link,
.left-menu-container__link:active,
.left-menu-container__link:hover {
  color: #fff;
}

.left-menu-container__outer {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: transparent transparent;
}

.left-menu-container__outer::-webkit-scrollbar,
.left-menu-container__outer::-webkit-scrollbar-track {
  background-color: transparent;
}

.left-menu-container__outer::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.left-menu-container__inner {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 11px;
  position: relative;
}

@media (max-width: 1000px) {
  .left-menu-container__inner {
    padding: 12px 12px 22px;
  }
}

.left-menu-container__logo {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-menu-container__logo a {
  display: block;
}

.left-menu-container__head {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.left-menu-container__bonuses {
  margin-bottom: 14px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 7px;
  background-color: #1C2532;
  border-radius: 7px;
  padding: 6px;
}



.left-menu-container__birthday {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px 12px;
  margin-bottom: 4px;
  cursor: pointer;
  text-decoration: none;
}

.left-menu-container__birthday.active,
.left-menu-container__birthday:hover {
  background: #202a39;
}

.left-menu-container__birthday.active .left-menu-container__birthday-title {
  color: #fff;
}

.left-menu-container__birthday-img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.left-menu-container__birthday-title {
  color: #93acd3;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
}

.left-menu-container__questions {
  background: url(/_nuxt/img/menu-bg.7e0d126.png) 100% no-repeat,
    linear-gradient(93.73deg, #1d2632, #4718e5 118.75%), #2a3546;
  background-size: contain;
  border-radius: 8px;
  margin-bottom: 8px;
  height: 48px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 13px;
  line-height: 1;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.left-menu-container__questions+.left-menu-container__bonuses {
  margin-top: 0;
}

.left-menu-container__questions-subtitle {
  color: hsla(0, 0%, 100%, 0.65);
  font-weight: 700;
}

.left-menu-container__footer {
  position: relative;
  padding: 5px 0;
  background-color: #1C2532;
  border-radius: 5px;
}

.left-menu-container__footer>.button {
  justify-content: flex-start;
  color: #93ACD3;
  font-weight: 500;
  background: #1C2532;
  border-radius: 0;
  padding: 10px 18px;
  font-size: 14px;
  border-bottom: 1px solid #222E3F;
}

.primary-menu {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 8px;
  margin-bottom: 14px;
}

.primary-menu__item-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 67px;
  background-color: #202A39;
  border-radius: 7px;
  cursor: pointer;

}

.primary-menu__item-link-active {
  background-color: #3A485F;
}


.primary-menu__item-link .el-image,
.primary-menu__item-link img {
  width: 36px;
  height: 36px;
}

.primary-menu__item-link span {
  font-weight: bold;
  color: #93ACD3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
  margin-top: 2px;
}

.secondary-menu {
  height: 67px;
  background-color: #202A39;
  border-radius: 7px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #93ACD3;
}

.aside-download-icon {
  width: 68px;
  height: 66px;
  margin-right: 9px;
}

.icon-arrow {
  /* margin-left: 8px; */
  transform: rotate(0deg);
  transition: fill 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.active .icon-arrow {
  transform: rotate(270deg);
  transform-origin: center;
}

.dropdown {
  position: relative;
}

.dropdown__label {
  display: inline-block;
  margin-bottom: 4px;
  line-height: 1.33;
  font-weight: 600;
  color: #55657e;
}

.dropdown__trigger,
.dropdown__trigger-custom {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #93ACD3;
}

.dropdown__trigger-custom>div.icon,
.dropdown__trigger>div.icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown__trigger-custom>div.icon svg,
.dropdown__trigger>div.icon svg {
  fill: #93acd3;
  width: 100%;
  height: 100%;
}

.dropdown__trigger-custom>div.icon img,
.dropdown__trigger>div.icon img {
  width: 100%;
  height: 100%;
  display: block;
}

.dropdown__trigger-custom>span.text,
.dropdown__trigger>span.text {
  flex: 1 1;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.dropdown__trigger {
  background-color: #1c2532;
  border-color: #1c2532;
  border-style: solid;
  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
}


.dropdown__inner {
  z-index: 1;
  position: absolute;
  transform: translateY(0);
}

.dropdown__content {
  background-color: #1c2532;
  border-color: #1c2532;
  border-style: solid;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
}

.dropdown__content .input {
  margin-bottom: 6px;
}

.dropdown__empty {
  color: #55657e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown_fullwidth {
  display: block;
  width: 100%;
}

.dropdown_disabled {
  pointer-events: none;
}

.dropdown_disabled .dropdown__trigger span {
  color: #55657e;
}

.dropdown_disabled .dropdown__trigger svg {
  fill: #55657e;
}

.dropdown_is-error .dropdown__trigger {
  border-color: #ed1d49;
}

.dropdown_top .dropdown__inner,
.dropdown_topLeft .dropdown__inner,
.dropdown_topRight .dropdown__inner {
  bottom: 100%;
  margin-top: 0 !important;
}

.dropdown_top .icon-arrow,
.dropdown_topLeft .icon-arrow,
.dropdown_topRight .icon-arrow {
  fill: #93acd3;
  pointer-events: none;
  transform: rotate(180deg);
  transition: fill 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.dropdown_top.dropdown_opened .icon-arrow,
.dropdown_topLeft.dropdown_opened .icon-arrow,
.dropdown_topRight.dropdown_opened .icon-arrow {
  transform: rotate(0deg);
  transform-origin: center;
}

.dropdown_bottom .dropdown__inner,
.dropdown_bottomLeft .dropdown__inner,
.dropdown_bottomRight .dropdown__inner {
  top: 100%;
  margin-bottom: 0 !important;
}

.dropdown_bottom .icon-arrow,
.dropdown_bottomLeft .icon-arrow,
.dropdown_bottomRight .icon-arrow {
  fill: #93acd3;
  pointer-events: none;
  transform: rotate(0deg);
  transition: fill 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.dropdown_bottom.dropdown_opened .icon-arrow,
.dropdown_bottomLeft.dropdown_opened .icon-arrow,
.dropdown_bottomRight.dropdown_opened .icon-arrow {
  transform: rotate(180deg);
  transform-origin: center;
}

.dropdown_bottomLeft .dropdown__inner,
.dropdown_topLeft .dropdown__inner {
  left: 0;
}

.dropdown_bottomRight .dropdown__inner,
.dropdown_topRight .dropdown__inner {
  right: 0;
}

.dropdown_bottom .dropdown__inner,
.dropdown_top .dropdown__inner {
  right: 0;
  left: 0;
}

.dropdown_sm.dropdown--join.dropdown_opened .dropdown__trigger {
  border-radius: 10px 10px 0 0;
}

.dropdown_sm.dropdown--join .dropdown__trigger {
  background-color: #1c2532;
}

.dropdown_sm.dropdown--join .dropdown__trigger:hover {
  background-color: #1c2532;
  border-color: #1c2532;
}

.dropdown_sm.dropdown--join .dropdown__content {
  border-radius: 0 0 10px 10px;
  box-shadow: none;
}

.dropdown_sm.dropdown--join .dropdown__inner {
  margin-top: 0;
  position: static;
}

.dropdown_sm .dropdown__label {
  font-size: 11px;
}

.dropdown_sm .dropdown__trigger-custom .icon-arrow,
.dropdown_sm .dropdown__trigger .icon-arrow {
  width: 16px;
  height: 16px;
  margin-left: 2px;
  margin-right: -4px;
}

.dropdown_sm .dropdown__trigger-custom>div.icon,
.dropdown_sm .dropdown__trigger>div.icon {
  margin-left: -3.2px;
  margin-right: 2px;
  width: 16px;
  height: 16px;
}

.dropdown_sm .dropdown__trigger-custom>span.text,
.dropdown_sm .dropdown__trigger>span.text {
  line-height: 16px;
}

.dropdown_sm .dropdown__trigger {
  font-size: 10px;
  border-radius: 8px;
  padding: 3px 7px;
  border-width: 1px;
}

.dropdown_sm .dropdown__inner {
  margin-top: 6px;
  margin-bottom: 6px;
  z-index: 2;
}

.dropdown_sm .dropdown__content {
  z-index: 1;
  font-size: 10px;
  padding: 3px;
  border-radius: 8px;
  border-width: 1px;
}

.dropdown_sm .dropdown__empty {
  font-size: 10px;
  min-height: 50px;
}

.dropdown_md.dropdown--join.dropdown_opened .dropdown__trigger {
  border-radius: 10px 10px 0 0;
}

.dropdown_md.dropdown--join .dropdown__trigger {
  background-color: #1c2532;
}

.dropdown_md.dropdown--join .dropdown__trigger:hover {
  background-color: #1c2532;
  border-color: #1c2532;
}

.dropdown_md.dropdown--join .dropdown__content {
  border-radius: 0 0 10px 10px;
  box-shadow: none;
}

.dropdown_md.dropdown--join .dropdown__inner {
  margin-top: 0;
  position: static;
}

.dropdown_md .dropdown__label {
  font-size: 12px;
}

.dropdown_md .dropdown__trigger-custom .icon-arrow,
.dropdown_md .dropdown__trigger .icon-arrow {
  width: 20px;
  height: 20px;
  margin-left: 4px;
  margin-right: -4px;
}

.dropdown_md .dropdown__trigger-custom>div.icon,
.dropdown_md .dropdown__trigger>div.icon {
  margin-left: 0px;
  margin-right: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown_md .dropdown__trigger-custom>span.text,
.dropdown_md .dropdown__trigger>span.text {
  line-height: 20px;
}

.dropdown_md .dropdown__trigger {
  font-size: 12px;
  padding: 10px 18px;
  border-width: 1px;
}

.dropdown_md .dropdown__inner {
  margin-top: 6px;
  margin-bottom: 6px;
  z-index: 2;
}

.dropdown_md .dropdown__content {
  z-index: 1;
  font-size: 12px;
  padding: 3px;
  border-radius: 10px;
  border-width: 1px;
}

.dropdown_md .dropdown__empty {
  font-size: 12px;
  min-height: 50px;
}

.dropdown_lg.dropdown--join.dropdown_opened .dropdown__trigger {
  border-radius: 10px 10px 0 0;
}

.dropdown_lg.dropdown--join .dropdown__trigger {
  background-color: #1c2532;
}

.dropdown_lg.dropdown--join .dropdown__trigger:hover {
  background-color: #1c2532;
  border-color: #1c2532;
}

.dropdown_lg.dropdown--join .dropdown__content {
  border-radius: 0 0 10px 10px;
  box-shadow: none;
}

.dropdown_lg.dropdown--join .dropdown__inner {
  margin-top: 0;
  position: static;
}

.dropdown_lg .dropdown__label {
  font-size: 12px;
}

.dropdown_lg .dropdown__trigger-custom .icon-arrow,
.dropdown_lg .dropdown__trigger .icon-arrow {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  margin-right: -4px;
}

.dropdown_lg .dropdown__trigger-custom>div.icon,
.dropdown_lg .dropdown__trigger>div.icon {
  margin-left: -4.8px;
  margin-right: 6px;
  width: 24px;
  height: 24px;
}

.dropdown_lg .dropdown__trigger-custom>span.text,
.dropdown_lg .dropdown__trigger>span.text {
  line-height: 24px;
}

.dropdown_lg .dropdown__trigger {
  font-size: 14px;
  border-radius: 10px;
  padding: 8px 12px;
  border-width: 2px;
}

.dropdown_lg .dropdown__inner {
  margin-top: 8px;
  margin-bottom: 8px;
  z-index: 2;
}

.dropdown_lg .dropdown__content {
  z-index: 1;
  font-size: 14px;
  padding: 2px;
  border-radius: 10px;
  border-width: 2px;
}

.dropdown_lg .dropdown__empty {
  font-size: 14px;
  min-height: 50px;
}

.dropdown_xl.dropdown--join.dropdown_opened .dropdown__trigger {
  border-radius: 10px 10px 0 0;
}

.dropdown_xl.dropdown--join .dropdown__trigger {
  background-color: #1c2532;
}

.dropdown_xl.dropdown--join .dropdown__trigger:hover {
  background-color: #1c2532;
  border-color: #1c2532;
}

.dropdown_xl.dropdown--join .dropdown__content {
  border-radius: 0 0 10px 10px;
  box-shadow: none;
}

.dropdown_xl.dropdown--join .dropdown__inner {
  margin-top: 0;
  position: static;
}

.dropdown_xl .dropdown__label {
  font-size: 14px;
}

.dropdown_xl .dropdown__trigger-custom .icon-arrow,
.dropdown_xl .dropdown__trigger .icon-arrow {
  width: 32px;
  height: 32px;
  margin-left: 8px;
  margin-right: -8px;
}

.dropdown_xl .dropdown__trigger-custom>div.icon,
.dropdown_xl .dropdown__trigger>div.icon {
  margin-left: -6.4px;
  margin-right: 6px;
  width: 32px;
  height: 32px;
}

.dropdown_xl .dropdown__trigger-custom>span.text,
.dropdown_xl .dropdown__trigger>span.text {
  line-height: 32px;
}

.dropdown_xl .dropdown__trigger {
  font-size: 16px;
  border-radius: 14px;
  padding: 14px 18px;
  border-width: 2px;
}

.dropdown_xl .dropdown__inner {
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 2;
}

.dropdown_xl .dropdown__content {
  z-index: 1;
  font-size: 16px;
  padding: 4px;
  border-radius: 14px;
  border-width: 2px;
}

.dropdown_xl .dropdown__empty {
  font-size: 16px;
  min-height: 50px;
}

.dropdown-menu__inner {
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-menu__inner_has-scroll {
  padding-right: 4px;
}

.dropdown-menu__link {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 0;
  color: #55657e;
  transition: color 0.1s ease-in-out;
}

.dropdown-menu__link:focus,
.dropdown-menu__link:hover {
  color: #fff;
}

.dropdown-menu__link:active {
  color: #93acd3;
}

.dropdown-menu__link>div.icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu__link>div.icon svg {
  fill: #93acd3;
  width: 100%;
  height: 100%;
}

.dropdown-menu__link>div.icon img {
  width: 73%;
}

.dropdown-menu__link>span.text {
  flex: 1 1;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.dropdown-menu__link_selected {
  color: #fff;
  pointer-events: none;
  background-color: #2a3546;
}

.dropdown-menu_sm .dropdown-menu__link {
  border-radius: 4px;
  padding: 4px;
}

.dropdown-menu_sm .dropdown-menu__link>div.icon {
  margin-left: -3.2px;
  margin-right: 2px;
  width: 16px;
  height: 16px;
}

.dropdown-menu_sm .dropdown-menu__link>span.text {
  line-height: 16px;
}

.dropdown-menu_md .dropdown-menu__link {
  border-radius: 6px;
  padding: 6px;
}

.dropdown-menu_md .dropdown-menu__link>div.icon {
  margin-left: -4px;
  margin-right: 3px;
  width: 20px;
  height: 20px;
}

.dropdown-menu_md .dropdown-menu__link>span.text {
  line-height: 20px;
}

.dropdown-menu_lg .dropdown-menu__link {
  border-radius: 6px;
  padding: 8px 10px;
}

.dropdown-menu_lg .dropdown-menu__link>div.icon {
  margin-left: -4.8px;
  margin-right: 6px;
  width: 24px;
  height: 24px;
}

.dropdown-menu_lg .dropdown-menu__link>span.text {
  line-height: 24px;
}

.dropdown-menu_xl .dropdown-menu__link {
  border-radius: 10px;
  padding: 8px 14px;
}

.dropdown-menu_xl .dropdown-menu__link>div.icon {
  margin-left: -6.4px;
  margin-right: 6px;
  width: 32px;
  height: 32px;
}

.dropdown-menu_xl .dropdown-menu__link>span.text {
  line-height: 32px;
}

.gift {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 10px;
  height: 10px;
  margin-right: 0;
}

.is-wallet {
  display: flex;
  width: 100%;
}

.is-wallet .currency__balances {
  margin-left: auto;
}

.is-wallet .currency__label {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #93acd3;
}

.is-wallet .currency__label:hover {
  color: #fff;
}

.left-menu-container__footer .el-dropdown {
  width: 100%;
}

.social-icons a {
  color: #55657e;
}

.button_fullwidth {
  display: flex;
  align-items: center;
  line-height: 20px;
}

.icon-reddit {
  width: 30px;
  transform: translate(0, -10%);
  margin-right: 8px;
}

.icon-svg-livechat {
  margin-right: 5px;
  color: #55657e;
}

.left-menu-container__footer .btn-toLiveSuppert {
  padding: 8px 10px;
}

.lan-dropdown {
  width: 212px;
}




.countdown-txt {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  font-weight: bold;
  color: transparent;
  background-image: linear-gradient(0deg, #FEF4FE 0%, #FDC6FD 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.new {
  width: 8px;
  height: 8px;
  background-color: red;
  display: inline-block;
  border-radius: 50%;
  margin-left: 4px;
  border: 1px solid #FFF;
}

.primary-menu .new {
  position: absolute;
  right: 6px;
  top: 6px;
}

.bonus {
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: 71px;
}

.bonus-img {
  width: 100%;
  height: 100%;
  display: block;
}

.bonus-img :deep(img) {
  object-fit: cover;
}

.bonus .new {
  position: absolute;
  right: 2px;
  top: 2px;
}

.el-icon-arrow-down-active {
  transform: rotate(180deg);
}
</style>
